import * as React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { ThemeProvider, CssBaseline } from "@material-ui/core";
import theme from "../../src/constants/theme";
import favicon from "../../src/assets/icons/favicon.png";

export default function TopLayout(props) {
  return (
    <React.Fragment>
      <Helmet>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <meta
          name="facebook-domain-verification"
          content="g2omhlv7fvpmaygcjki475ukit6p2a"
        />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Dosis:wght@400;700;800&family=Roboto:wght@100;400;500;700&display=swap"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
        <link
          href="https://fonts.cdnfonts.com/css/bakso-sapi"
          rel="stylesheet"
        />
        <link
          href="https://fonts.cdnfonts.com/css/frenchpress"
          rel="stylesheet"
        />
        <link rel="icon" type="image/png" href={favicon} />
      </Helmet>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        {props.children}
      </ThemeProvider>
    </React.Fragment>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node,
};
